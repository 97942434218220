import React from 'react';
import { useUserInfo } from '../hooks/useUserInfo/useUserInfo';
import { HardwareCollection, UserInfo } from '../model';
import { useAuthContext } from './authContext';

type UserInfoContextProps =
  | {
      userInfo: UserInfo;
      buildDocTree: () => Promise<void>;
    }
  | undefined;

const UserInfoContext = React.createContext<UserInfoContextProps>(undefined);

interface UserInfoProviderProps {
  children: React.ReactNode;
}

export const UserInfoProvider = ({ children }: UserInfoProviderProps) => {
  const userId = useAuthContext().state.userState?.user.uid;

  let value: UserInfoContextProps = undefined;

  if (userId) {
    const userInfoHook = useUserInfo(userId);

    const buildDocTree = async () => {
      await userInfoHook.buildDocTree();
    };

    value = {
      userInfo: userInfoHook.userInfo,
      buildDocTree: buildDocTree,
    };
  }

  return <UserInfoContext.Provider value={value}>{children}</UserInfoContext.Provider>;
};

export const useUserInfoContext = () => {
  const context = React.useContext(UserInfoContext);
  if (context === undefined) {
    throw new Error(
      'useUserInfoContext() is not being used within the proper scope. It can only be used within UserInfoContext.',
    );
  }
  return context;
};
