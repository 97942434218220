import React from 'react';
import { IonGrid, IonRow, IonCol, IonIcon, IonContent, IonHeader, IonItem, IonList, IonTitle } from '@ionic/react';
import './index.css';
import { businessOutline, mailOutline, person, powerOutline } from 'ionicons/icons';
import { User, UserInfo } from '../../model';

interface ProfileProps {
  user: User;
  Info: UserInfo;
}

export const YourProfile = ({ user, Info }: ProfileProps) => {
  return (
    <>
      <IonHeader class='ion-no-border'>
        <IonTitle className='userInfo-modal-header'>Your Profile</IonTitle>
      </IonHeader>
      <IonContent className='' scrollEvents={false} scrollY={false}>
        <IonGrid fixed>
          <IonRow className='ion-align-items-center ion-justify-content-center userInfo-modal-row'>
            <IonCol size='12'>
              <IonList lines='none' mode='ios'>
                <IonItem className='userInfo-modal-listItem'>
                  <IonIcon src={person} color='primary' slot='start' />
                  {Info.firstName} {Info.lastName}
                </IonItem>
                <IonItem className='userInfo-modal-listItem'>
                  <IonIcon src={mailOutline} color='primary' slot='start' />
                  {user.email}
                </IonItem>

                <IonItem className='userInfo-modal-listItem'>
                  <IonIcon src={businessOutline} color='primary' slot='start' />
                  {Info.organization.org_name}
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </>
  );
};
